import React from 'react';
import './assets/styles/home.css';

const App = () => {
  return (
    <div className="under-construction-container">
      <div className="header">
        <h1>{'{Co} Design'}</h1>
        <button className="back-button">Back to Home</button>
      </div>
      <div className="content">
        <div className="construction-illustration">
          {/* Illustration placeholder, replace with an actual illustration if needed */}
          <div className="crane"></div>
          <div className="monitor">
            <div className="image-placeholder"></div>
          </div>
        </div>
        <h2>Under Construction!</h2>
        <p>To make things right we need some time to rebuild</p>
        <div className="notification">
          <p>Get notified when we are done.</p>
          <div className="email-form">
            <input type="email" placeholder="Your Email" />
            <button type="submit">Send</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
